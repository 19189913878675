.background-image-container {
  background-image: url("./eats.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 100%;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.logo {
  width: 400px;
  padding: 30px;
  max-width: 100%; /* Ensure the logo doesn't exceed its container's width */
}

.input-box-div {
  display: flex;
  justify-content: center;
}

.input-box {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 5px;
  flex-grow: 1;
  max-width: 700px;
}

.search-button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 40%; /* Make the button round-shaped */
  cursor: pointer;
  transition: background-color 0.3s;
}

.prompting-eats {
  font-size: 50px;
  color: green;
  font-weight: 800;
  margin-left: 10px;
  display: flex; /* Enable flexbox for centering */
  align-items: center; /* Center vertically */
}

.search-button:hover {
  background-color: #2e7d32;
}

.cuisine-pills {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  gap: 5px;
}

.cuisine-pill {
  padding: 8px 12px;
  margin-right: 10px;
  font-size: 12px;
  background-color: #4caf50;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.cuisine-pill.active {
  background-color: #2e7d32;
}

.try {
  padding: 10px;
}

.details-background {
  background-image: url("./eats.png");
  background-size: cover;
  background-position: center;
  opacity: 1.0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.loading-page {
  width: 100%;
  height: 100vh; 
  background-image: url("https://giphy.com/embed/3o7btVLx1sXHH9iFPy");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  text-align: center;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: rgb(242, 214, 57);
  animation: blink-animation 1s infinite;
}

.blinking-ellipsis {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.details-sticky-header {
  position: sticky;
  top: 0;
  background-color: rgb(98, 173, 98);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure the elements are spaced out */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details-search-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.details-home-button {
  background: none;
  border: none;
  cursor: pointer;
}

.details-home-logo {
  width: 40px; /* Adjust the size as needed */
  height: auto;
}

.input-search {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.details-search-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.details-search-button {
  margin-left: 8px; /* Add some spacing between the input field and the search button */
  background: none;
  border: none;
  cursor: pointer;
}

.details-search-button:hover {
  background-color: #0056b3;
}
